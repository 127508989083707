.projects-container {
    font-family: "Raleway", sans-serif;
    max-width: 100%;
    display: grid;
    background: #f3efe0;
    margin: 0 auto;
}

.projects-body {
    max-width: 800px;
    padding-bottom: 40px;
    margin: auto;
}

.projects-grid {
    display: grid;
    max-width: 800px;
    margin:auto;
    align-items: center;
}

.projects-row {
    display: flex;
    max-width: 800px;
    object-fit: contain;
    margin:auto;

    align-items: center;
}

.project-link-pic {
    /* max-width: 390px; */
    width: 390px;
    height: 200px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 15px;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    border-color: #222;
    
}

/* media queries */
@media (max-width: 1090px) {
    .projects-row {
        flex-direction: column;
        max-width: 200px;
    }
    

}

@media (max-width: 1170px) {
    .project-link-pic {
        max-width: 350px;
    }
}