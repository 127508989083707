.contact-container {
  font-family: "Raleway", sans-serif;
  max-width: 100%;
  display: grid;
  background: #222;
  margin: 0 auto;
}

.contact-body {
  max-width: 800px;
  padding-bottom: 40px;
  margin-right: auto;
  margin-left: auto;
}

.icon {
  float: left;
  max-width: 32px;
  margin-right: 1rem;
  color: white;
}

.contacts {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

a {
  color: #727878;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}

.social-buttons {
  display: flex;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

/* media queries */
@media (max-width: 1090px) {
  .contacts {
    flex-direction: column;
  }

  .contacts a {
    margin: 5px;
  }
}
