.about-container {
  font-family: "Raleway", sans-serif;
  max-width: 100%;
  display: grid;
  background: white;
  margin: 0 auto;
}

.profile {
  max-width: 800px;
  padding-bottom: 40px;
  margin-right: auto;
  margin-left: auto;
}

.title {
  text-align: center;
}

.title h1 {
  color: #029292;
  font-size: 54px;
  font-weight: 200;
  margin-bottom: 1.4rem;
}

.title h2 {
  color: #727878;
  font-weight: 200;
}

.row {
  display: flex;
  margin: 0 auto;
}

.row h2 {
  color: #029292;
}
.row h3 {
  color: #434242;
}
.line {
  width: 100%;
  height: 1px;
  background: lightgray;
  margin-bottom: 2rem;
}

.row {
  display: flex;
}

.row p {
  color: #434242;
  max-width: 350px;
  font-size: large;
}

.about-pic {
  border-radius: 50%;
  max-width: 200px;
  padding: 3rem;
  padding-top: 3rem;
}

/* media queries */
@media (max-width: 1090px) {
  .row {
    flex-direction: column;
  }

  .row p {
    max-width: 300px;
  }
}
